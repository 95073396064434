.funding-sim-form {
    table.vectors-table {
        width: 100%;
        text-align: center;

        td,
        th {
            padding: 4px;
            text-align: center;
            border: 1px solid #141320;
            font-size: 12px;
        }

        td:first-child {
            text-align: left;
            width: 100px
        }
    }
}