.tw-data-table {
    td {
        font-size: 13px;
    }

    th.tw-data-table__header {
        &.tw-data-table__header--sortable {
            cursor: pointer;
        }
    }

    &.tw-data-table--has-actions {
        td:last-child {
            text-align: right;
        }
    }
}